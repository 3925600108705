<template>
  <div :class="[isDevelopment ? $style.dev : $style.production]">
    <code
      v-if="isDevelopment && error"
      v-html="error"
      :class="$style.error"
    />
    <div v-else-if="!isDevelopment">
      {{ $t('error.pageNotFound') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  error: {
    type: Object,
    required: true
  }
})

const runtimeConfig = useRuntimeConfig()
const env = runtimeConfig.env || runtimeConfig.public.env
const isDevelopment = env?.toLowerCase() === 'development'
</script>

<style module>
.production {
  padding: var(--lh, 1em) var(--unit--horizontal);
}

.dev {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error {
  composes: container from global;

  background: var(--color--gray);
  color: var(--color--white);

  padding: var(--lh, 1em) var(--unit--horizontal);

  text-wrap: wrap;
  word-break: break-all;

  overflow: scroll;
  max-height: 50vh;

  border-radius: 1rem;

}


</style>
